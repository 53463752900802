import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./Header.module.css";

const Header = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isBannerOpen, setIsBannerOpen] = useState(true);

    const openModalHandler = (modal) => {
        if (modal === "account") {
            props.accountOpen();
        } else if (modal === "settings") {
            props.settingsOpen();
        }
        setIsOpen(false);
    };

    useEffect(() => {
        const timestamp = localStorage.getItem("bannerDismissedAt");
        if (!timestamp) return true;

        // show banner if it's been more than 7 days since the user dismissed it
        const val =
            new Date(timestamp) <
            new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        setIsBannerOpen(val);
    }, []);

    return (
        <>
            <header className={styles.header}>
                {isBannerOpen ? (
                    <section className={styles.container}>
                        <p className={styles.bannerText}>
                            Dwoth is a indie project, please consider supporting
                            us.
                        </p>
                        <div className={styles.bannerButtons}>
                            <a
                                href="https://store.anthonysmendes.com/l/support"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className={styles.bannerButton}>
                                    Sponsor us
                                </button>
                            </a>
                            <button
                                className={styles.bannerButton}
                                onClick={() => {
                                    localStorage.setItem(
                                        "bannerDismissedAt",
                                        new Date().toISOString()
                                    );
                                    setIsBannerOpen(false);
                                }}
                            >
                                Dismiss
                            </button>
                        </div>
                    </section>
                ) : (
                    <div className={styles.container}>
                        <img
                            src="/logo.png"
                            alt="Dwoth Logo"
                            className={styles.logo}
                        />
                        <div className={styles.accIcon}>
                            <i
                                className={
                                    styles.profile + " far fa-user-circle"
                                }
                                onClick={() => setIsOpen(!isOpen)}
                            ></i>
                            {isOpen && (
                                <div className={styles.modal}>
                                    <button
                                        className={styles.pwmodal}
                                        onClick={() =>
                                            openModalHandler("settings")
                                        }
                                    >
                                        Settings
                                    </button>
                                    <button
                                        className={styles.pwmodal}
                                        onClick={() =>
                                            openModalHandler("account")
                                        }
                                    >
                                        Change Password
                                    </button>
                                    <Link to="/logout">
                                        <button>Logout</button>
                                    </Link>
                                </div>
                            )}
                            {isOpen && (
                                <div
                                    className={styles.overlay}
                                    onClick={() => setIsOpen(false)}
                                ></div>
                            )}
                        </div>
                    </div>
                )}
            </header>
        </>
    );
};

export default Header;
