import styles from "./ViewUser.module.css";

const ViewUser = (props) => {
    const { user } = props;

    return (
        <div className={styles.overlay} onClick={props.closeModalHandler}>
            <div className={styles.main} onClick={(e) => e.stopPropagation()}>
                <div className={styles.header}>
                    <h1>Managing User</h1>
                    <button
                        className={styles.close}
                        onClick={props.closeModalHandler}
                    >
                        Close
                    </button>
                </div>
                <p className={styles.premium}>
                    {user.renewalDate < new Date()
                        ? "Free Member"
                        : "Premium Member 👑"}
                </p>
                <div className={styles.details}>
                    <p>
                        Name: <span>{user.name}</span>
                    </p>
                    <p>
                        Email: <span>{user.email}</span>
                    </p>
                    <p>
                        UID: <span>{user.uid}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ViewUser;
